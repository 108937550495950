import { coreApi as api } from "../core/BaseApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiPerformanceCardsGetPerformanceCardOptions: build.query<
      GetApiPerformanceCardsGetPerformanceCardOptionsApiResponse,
      GetApiPerformanceCardsGetPerformanceCardOptionsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/PerformanceCards/GetPerformanceCardOptions`,
        params: { companyGroupId: queryArg.companyGroupId },
      }),
    }),
    postApiPerformanceCardsReview: build.mutation<
      PostApiPerformanceCardsReviewApiResponse,
      PostApiPerformanceCardsReviewApiArg
    >({
      query: (queryArg) => ({
        url: `/api/PerformanceCards/Review`,
        method: "POST",
        body: queryArg.performanceCardReviewRequestDto,
      }),
    }),
    postApiPerformanceCardsCreatePerformanceCardWithAssignments: build.mutation<
      PostApiPerformanceCardsCreatePerformanceCardWithAssignmentsApiResponse,
      PostApiPerformanceCardsCreatePerformanceCardWithAssignmentsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/PerformanceCards/CreatePerformanceCardWithAssignments`,
        method: "POST",
        body: queryArg.performanceCardReviewRequestDto,
      }),
    }),
    getApiPerformanceCardsGetAllPerformanceCards: build.query<
      GetApiPerformanceCardsGetAllPerformanceCardsApiResponse,
      GetApiPerformanceCardsGetAllPerformanceCardsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/PerformanceCards/GetAllPerformanceCards`,
        params: { companyGroupId: queryArg.companyGroupId },
      }),
    }),
    getApiPerformanceCardsGetPerformanceCard: build.query<
      GetApiPerformanceCardsGetPerformanceCardApiResponse,
      GetApiPerformanceCardsGetPerformanceCardApiArg
    >({
      query: (queryArg) => ({
        url: `/api/PerformanceCards/GetPerformanceCard`,
        params: { id: queryArg.id },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetApiPerformanceCardsGetPerformanceCardOptionsApiResponse =
  /** status 200 OK */ PerformanceCardOptionsKeyValueDto;
export type GetApiPerformanceCardsGetPerformanceCardOptionsApiArg = {
  companyGroupId?: string;
};
export type PostApiPerformanceCardsReviewApiResponse =
  /** status 200 OK */ PerformanceCardReviewDto;
export type PostApiPerformanceCardsReviewApiArg = {
  performanceCardReviewRequestDto: PerformanceCardReviewRequestDto;
};
export type PostApiPerformanceCardsCreatePerformanceCardWithAssignmentsApiResponse =
  unknown;
export type PostApiPerformanceCardsCreatePerformanceCardWithAssignmentsApiArg =
  {
    performanceCardReviewRequestDto: PerformanceCardReviewRequestDto;
  };
export type GetApiPerformanceCardsGetAllPerformanceCardsApiResponse =
  /** status 200 OK */ PerformanceCardListDto[];
export type GetApiPerformanceCardsGetAllPerformanceCardsApiArg = {
  companyGroupId?: string;
};
export type GetApiPerformanceCardsGetPerformanceCardApiResponse =
  /** status 200 OK */ PerformanceCardListDto;
export type GetApiPerformanceCardsGetPerformanceCardApiArg = {
  id?: string;
};
export type GuidStringKeyValuePair = {
  key?: string;
  value?: string | null;
};
export type PerformanceCardOptionsKeyValueDto = {
  activePeriods?: GuidStringKeyValuePair[] | null;
  performanceSystems?: GuidStringKeyValuePair[] | null;
  targetAudiences?: GuidStringKeyValuePair[] | null;
  scaleTypes?: GuidStringKeyValuePair[] | null;
};
export type PeriodDto = {
  id?: string | null;
  periodNo?: string | null;
  periodName?: string | null;
  startDate?: string;
  endDate?: string;
  companyGroupId?: string;
};
export type PerformanceSystemType = 1 | 2;
export type OkrDetailDto = {
  id?: string;
  okrShapeId?: string;
  okrRelationshipId?: string;
  okrTypeIds?: string[] | null;
  okrWeightId?: string;
  okrPrivacyId?: string;
  okrTransparencyId?: string;
  okrProgressId?: string;
  minValue?: number;
  maxValue?: number;
};
export type KpiDetailDto = {
  id?: string;
  kpiRelationshipId?: string;
  targetTypesIds?: string[] | null;
  compentencyId?: string;
  goalMeasurementTypeId?: string;
  goalTargetMeasurementSourceIds?: string[] | null;
};
export type KpiGoalCompentencyDetailsDto = {
  id?: string;
  performanceSystemId?: string;
  role?: string;
  goalPercentage?: number;
  competencyPercentage?: number;
  corporateGoals?: number;
  departmentGoals?: number;
  individualGoals?: number;
  managerialCompetencies?: number;
  coreCompetencies?: number;
};
export type RangeType =
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14
  | 15;
export type PerformanceSystemRangeDto = {
  id?: string;
  rangeType?: RangeType;
  minValue?: number;
  maxValue?: number;
};
export type PerformanceSystemDto = {
  id?: string;
  systemNumber?: string | null;
  systemName?: string | null;
  performanceSystemType?: PerformanceSystemType;
  okrDetails?: OkrDetailDto[] | null;
  kpiDetails?: KpiDetailDto[] | null;
  kpiGoalCompentencyDetails?: KpiGoalCompentencyDetailsDto[] | null;
  performanceSystemRanges?: PerformanceSystemRangeDto[] | null;
  companyGroupId?: string;
};
export type CompanyGroupDto = {
  id?: string;
  groupName?: string | null;
};
export type CompanyDto = {
  id?: string;
  name?: string | null;
  address?: string | null;
  email?: string | null;
  companyGroupId?: string;
  companyGroup?: CompanyGroupDto;
};
export type UserDto = {
  id?: string;
  userName?: string | null;
  companyId?: string;
  employeeNumber?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  function?: string | null;
  subFunction?: string | null;
  department?: string | null;
  subDepartment?: string | null;
  title?: string | null;
  titleGroup?: string | null;
  employeeType?: string | null;
  workLocation?: string | null;
  startDate?: string;
  managerName?: string | null;
  managerSurname?: string | null;
  email?: string | null;
  phone?: string | null;
  company?: CompanyDto;
};
export type ScaleType = 1 | 2 | 3;
export type EvaluationType = 1 | 2;
export type ScaleDetailDto = {
  result?: string | null;
  minScore?: number;
  maxScore?: number;
  resultDescription?: string | null;
  quotaResult?: number;
  isQuotaChecked?: boolean;
  evaluationType?: EvaluationType;
};
export type ScaleDto = {
  id?: string;
  scaleNumber?: string | null;
  scaleName?: string | null;
  scaleType?: ScaleType;
  scaleDetails?: ScaleDetailDto[] | null;
  companyGroupId?: string;
};
export type PerformanceCardReviewDto = {
  cardName?: string | null;
  periodName?: PeriodDto;
  performanceSystemName?: PerformanceSystemDto;
  targetAudienceList?: UserDto[] | null;
  scaleTypeName?: ScaleDto;
};
export type PerformanceCardReviewRequestDto = {
  cardName?: string | null;
  companyGroupId?: string;
  periodId?: string;
  performanceSystemId?: string;
  targetAudienceId?: string;
  scaleTypeId?: string;
};
export type PerformanceCardListDto = {
  id?: string;
  cardName?: string | null;
  periodName?: PeriodDto;
  performanceSystemName?: PerformanceSystemDto;
  targetAudienceList?: UserDto[] | null;
  scaleTypeName?: ScaleDto;
  createdAt?: string;
};
export const {
  useGetApiPerformanceCardsGetPerformanceCardOptionsQuery,
  useLazyGetApiPerformanceCardsGetPerformanceCardOptionsQuery,
  usePostApiPerformanceCardsReviewMutation,
  usePostApiPerformanceCardsCreatePerformanceCardWithAssignmentsMutation,
  useGetApiPerformanceCardsGetAllPerformanceCardsQuery,
  useLazyGetApiPerformanceCardsGetAllPerformanceCardsQuery,
  useGetApiPerformanceCardsGetPerformanceCardQuery,
  useLazyGetApiPerformanceCardsGetPerformanceCardQuery,
} = injectedRtkApi;
