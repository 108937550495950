// src/components/PerformanceGoalEntry/PerformanceGoalEntryModal.tsx
import React, { useEffect, useState } from 'react';
import { Modal, Form, Button, Select, Popover, FormInstance } from 'antd';
import GoalTable from './GoalTable';
import { PerformanceSystemType } from '../../enums/PerformanceSystemType';
import { periods } from '../../data/data';
import FormItemCombobox from '../FormItemCombobox';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useLazyGetApiPeriodGetActivePeriodsQuery } from '../../api/services/period';
import { DataItem } from '../../models/DataItem';
import { t } from 'i18next';
import { useSelector, useDispatch } from 'react-redux';
import { setLoading, setError } from '../../features/app/appSlice';
import { RootState } from '../../app/store';
import { ParameterType, usePostApiParameterGetParametersMutation } from '../../api/services/parameters';
import { extractParam } from '../../helper/paramHelper';
import { useLazyGetApiPerformanceGoalGetPerformanceGoalsByCompanyGroupIdQuery, usePostApiPerformanceGoalSavePerformanceGoalsMutation } from '../../api/services/PerformanceGoal';

interface PerformanceGoalEntryModalProps {
  visible: boolean;
  onClose: () => void;
  initialValues?: any;
  form: FormInstance
}

const PerformanceGoalEntryModal: React.FC<PerformanceGoalEntryModalProps> = ({ initialValues, visible, onClose, form }) => {
  
  const systemType = Form.useWatch('systemType', form);
  const [fetchActivePeriods, { data: activePeriods }] = useLazyGetApiPeriodGetActivePeriodsQuery()
  const [getParameters, { data: parameters }] = usePostApiParameterGetParametersMutation()
  const [savePerformanceGoalOnService] = usePostApiPerformanceGoalSavePerformanceGoalsMutation()
  const user = useSelector((state: RootState) => state.auth.user);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user) {
      getActivePeriods()

      getParameters({
        companyGroupId: user.company?.companyGroupId ?? "",
        body: [
          7 as ParameterType, //HEDEF_OLCUM_KAYNAGI
        ]
      })
    }
  }, [user])

  const getActivePeriods = async () => {
    try {
      dispatch(setLoading(true));
      await fetchActivePeriods({
        companyGroupId: user?.company?.companyGroupId ?? ""
      }).unwrap()
    } catch (err: any) {
      if (err.status != "PARSING_ERROR")
        dispatch(setError(t('Dönemler sorgulanırken hata oluştu')));
    } finally {
      dispatch(setLoading(false));
    }
  }

  const savePerformanceGoal = async (values: any) => {
    try {
      dispatch(setLoading(true));
      await savePerformanceGoalOnService({
        id: initialValues?.id,
        savePerformanceGoalDto: {
          companyGroupId: user?.company?.companyGroupId ?? "",
          rows: values.rows,
          period: values.periodId
        }
      }).unwrap()

      debugger
      onClose()
    } catch (err: any) {
      if (err.status != "PARSING_ERROR")
        dispatch(setError(t('Performans hedefi kaydedilirken hata oluştu')));
    } finally {
      dispatch(setLoading(false));
    }
  }

  const onComplete = () => {
    form.submit()
  }

  const onSave = (values: any) => {
    savePerformanceGoal(values)
  }
  const content = (
    <div style={{ width: 300 }}>
      <h4>Sayfa Bilgisi</h4>
      <p>Bu sayfada performans sistemlerini tanımlayabilir ve yönetebilirsiniz.</p>
      <p>OKR veya KPI bazlı performans sistemlerini burada tanımlayarak, ilgili parametreleri belirleyebilirsiniz.</p>
      <p>Sistemin nasıl çalıştığını ve hangi parametrelerin gerekli olduğunu burada yapılandırabilirsiniz.</p>
    </div>
  );

  return (
    <Modal
      visible={visible}
      title={
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <span>{initialValues ? "Kurum Hedef Kart Güncelle" : "Kurum Hedef Kart Girişi"}</span>
          <Popover content={content} title="Sayfa Bilgisi" trigger="click">
            <Button
              shape="circle"
              icon={<InfoCircleOutlined />}
              style={{
                marginRight: 30,
                marginTop: -8,
                zIndex: 1000,
              }}
            />
          </Popover>
        </div>
      }
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>
          İptal
        </Button>,
        <Button key="save" type="primary" onClick={onComplete}>
          Kaydet
        </Button>,
      ]}
      width={1200}
      bodyStyle={{ maxHeight: '70vh', overflowY: 'auto' }}
    >
      <Form form={form} layout="vertical"
        onFinish={onSave}
        initialValues={{ ...initialValues, rows: initialValues?.goalDetails ?? null }}>
        <FormItemCombobox name="periodId" label="Performans Dönemi" placeholder="Performans Dönemi Seçiniz"
          datas={(activePeriods ?? []).map(k => ({
            id: k.id,
            name: k.periodName
          } as DataItem))}
          style={{ width: "300px" }} />
        <GoalTable goal={initialValues} />
      </Form>
    </Modal>
  );
};

export default PerformanceGoalEntryModal;
