import React, { useState } from 'react';
import { Menu, Layout } from 'antd';
import {
    DashboardOutlined,
    SettingOutlined,
    FileTextOutlined,
    CommentOutlined,
    UserOutlined,
    LogoutOutlined,
    PieChartOutlined,
    BarChartOutlined
} from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logout } from '../app/persistedSlice';

const { Sider } = Layout;
const { SubMenu, Divider } = Menu;

const SideMenu: React.FC = () => {
    const history = useHistory();
    const [selectedKeys, setSelectedKeys] = useState<string[]>([])
    const dispatch = useDispatch();
    
    const logoutClicked = () => {
        dispatch(logout())
        history.push('/');
    }

    const handleClick = (key: string) => {
        if (key === "logout") {
            setSelectedKeys([])
            logoutClicked()
            return
        }

        setSelectedKeys([key])
        if (key === "home") {
            history.push("/home");
            return;
        }

        history.push("/" + key.replaceAll("-", ""));
    };

    return (
        <Sider width={250} className="site-layout-background" style={{ position: 'relative', height: 'calc(100vh - 90px)', display: 'flex', flexDirection: 'column' }}>
            <Menu theme='dark' mode="vertical" selectedKeys={selectedKeys} style={{ flex: '0 1 auto', borderRight: 0, marginTop: 10 }} onSelect={(e) => { handleClick(e.key) }}>
                <SubMenu key="home" title="Ana Sayfa" icon={<DashboardOutlined />}>
                    <SubMenu key="dashboard-okrs" title="OKR">
                        <Menu.Item key="dashboard-strategic-okrs">Stratejik OKR'lar</Menu.Item>
                        <Menu.Item key="dashboard-department-okrs">Departman OKR'ları</Menu.Item>
                        <Menu.Item key="dashboard-my-teams-okrs">Ekibimin OKR'ları</Menu.Item>
                    </SubMenu>
                    <SubMenu key="dashboard-kpis" title="KPI">
                        <Menu.Item key="dashboard-corporate-goal-card">Kurum Hedef Kartı</Menu.Item>
                    </SubMenu>
                </SubMenu>
                <SubMenu key="my-performance-cards" title="Performans Kartlarım" icon={<FileTextOutlined />}>
                    <Menu.Item key="my-performance-cards-strategic-okrs">OKR'larım</Menu.Item>
                    <Menu.Item key="my-performance-cards-department-okrs">Departman OKR'ları</Menu.Item>
                    <Menu.Item key="my-performance-cards-my-okrs">Hedef Kartlarım</Menu.Item>
                    <Menu.Item key="my-performance-cards-my-teams-okrs">Çalışan Hedef Kartları</Menu.Item>
                </SubMenu>
                <SubMenu key="my-dialogs" title="1-1 Görüşmelerim" icon={<UserOutlined />}>
                    <Menu.Item key="my-dialogs-team">Çalışanlarımla</Menu.Item>
                    <Menu.Item key="my-dialogs-manager">Yöneticimle</Menu.Item>
                    <Menu.Item key="my-dialogs-my-teams">Ekibimin 1-1'leri</Menu.Item>
                </SubMenu>
                <SubMenu key="my-feedbacks" title="Geribildirimlerim" icon={<CommentOutlined />}>
                    <Menu.Item key="my-feedbacks-outgoing">Verdiğim Geribildirimler</Menu.Item>
                    <Menu.Item key="my-feedbacks-incoming">Aldığım Geribildirimler</Menu.Item>
                    <Menu.Item key="my-feedbacks-my-teams">Ekibimin Aldığı Geribildirimler</Menu.Item>
                </SubMenu>
                <SubMenu key="my-performance-results" title="Performans Sonuçlarım" icon={<BarChartOutlined />}>
                    <Menu.Item key="my-performance-results-okr">OKR</Menu.Item>
                    <Menu.Item key="my-performance-results-kpi">KPI</Menu.Item>
                </SubMenu>
                <SubMenu key="reports" title="Raporlar" icon={<PieChartOutlined />}>
                    <Menu.Item key="reports-okrs">OKR</Menu.Item>
                    <Menu.Item key="reports-kpis">KPI</Menu.Item>
                </SubMenu>
            </Menu>
            <div style={{ flex: '1 1 auto', overflowY: 'auto', borderRight: 0, maxHeight: 'calc(100vh - 150px)', position: "absolute", bottom: 0, width: "100%" }}>
                <Menu theme='dark' mode="vertical" selectedKeys={selectedKeys} onSelect={(e) => { handleClick(e.key) }} >
                    <Divider style={{ borderWidth: 0.5, borderColor: "rgba(255, 255, 255, 0.65)", marginRight: 10, marginLeft: 10, marginBottom: 10 }} />
                    <SubMenu key="management" icon={<SettingOutlined />} title="Yönetim Paneli">
                        <Menu.Item key="period-definition">Dönem Tanımlama</Menu.Item>
                        <Menu.Item key="target-audience-definition">Hedef Kitle Tanımlama</Menu.Item>
                        <Menu.Item key="scale-definition">Skala Tanımlama</Menu.Item>
                        <Menu.Item key="performance-systems-definition">Performans Sistemleri Tanımlama</Menu.Item>
                        <SubMenu key="corporate-goals-competency-entry" title="Kurum Hedefleri ve Yetkinlik Girişi">
                            <Menu.Item key="strategic-okr-entry">Stratejik OKR Girişi</Menu.Item>
                            <Menu.Item key="corporate-goal-card-entry">Kurum Hedef Kartı Girişi</Menu.Item>
                            <Menu.Item key="competency-entry">Yetkinlik Girişi</Menu.Item>
                        </SubMenu>
                        <Menu.Item key="performance-cards-creation">Performans Kartları Oluşturma</Menu.Item>
                        <Menu.Item key="pool-goal-transfer">Havuz Hedef Aktarım</Menu.Item>
                        <SubMenu key="dashboard-screens" title="Dashboard Ekranları">
                            <SubMenu key="okr-dashboard" title="OKR">
                                <Menu.Item key="strategic-okrs">Stratejik OKR'lar</Menu.Item>
                                <Menu.Item key="department-okrs">Departman OKR'ları</Menu.Item>
                                <Menu.Item key="my-team-okrs">Ekibimin OKR'ları</Menu.Item>
                                <Menu.Item key="my-okrs">OKR'larım</Menu.Item>
                            </SubMenu>
                            <SubMenu key="kpi-dashboard" title="KPI">
                                <Menu.Item key="corporate-goal-card">Kurum Hedef Kartı</Menu.Item>
                                <Menu.Item key="my-goal-card">Hedef Kartım</Menu.Item>
                            </SubMenu>
                        </SubMenu>
                        <Menu.Item key="one-on-one-meeting-schedule">1-1 Görüşme Takvimi Tanımlama</Menu.Item>
                        <Menu.Item key="nudging-emails">Dürtme Mailleri</Menu.Item>
                        <SubMenu key="role-authority-definitions" title="Rol ve Yetki Tanımlamaları">
                            <Menu.Item key="system-role">Sistem Rolu</Menu.Item>
                            <Menu.Item key="user-role">Kullanıcı Rolü</Menu.Item>
                        </SubMenu>
                        <Menu.Item key="parameters">Parametreler</Menu.Item>
                        <Menu.Item key="users">Kullanıcılar</Menu.Item>
                        <Menu.Item key="companies">Şirketler</Menu.Item>
                    </SubMenu>
                </Menu>
                <Menu theme='dark' mode="inline" selectedKeys={selectedKeys} style={{ flex: '0 1 auto', borderRight: 0 }}>
                    <Menu.Item key="logout" style={{ paddingLeft: 16 }} icon={<LogoutOutlined />} onClick={() => {
                        handleClick("logout")
                    }}>
                        Çıkış
                    </Menu.Item>
                </Menu>
            </div>
        </Sider>
    );
};

export default SideMenu;
