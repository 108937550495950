import React from 'react';
import { Form, Input, Select } from 'antd';
import { ScaleDetailDto } from '../../api/services/scale';

const { Option } = Select;

interface RowComponentProps {
  row: ScaleDetailDto;
  onRowChange: (key: number, updatedRow: ScaleDetailDto) => void;
  index: number
}

const RowComponent: React.FC<RowComponentProps> = ({ row, onRowChange, index }) => {

  return (
    <tr>
      <td>
        <Form.Item
          name={[`evaluationType_${row.evaluationType ?? ""}`, "scaleDetails", index ?? 0, "result"]}
          rules={[
            { required: true, message: "Bir Sonuç yazmalısınız" }
          ]}
          initialValue={row.result}
        >
          <Input style={{ height: 40, margin: 5 }} />
        </Form.Item>
      </td>
      <td>
        <Form.Item
          name={[`evaluationType_${row.evaluationType ?? ""}`, "scaleDetails", index ?? 0, "minScore"]}
          rules={[
            { required: true, message: "Alt Puan yazmalısınız" }
          ]}
          initialValue={row.minScore}
        >
          <Input type="number" style={{ height: 40, margin: 5 }} min={0} max={100} value={row.minScore ?? 0} className="no-spin" />
        </Form.Item>
      </td>
      <td>
        <Form.Item
          name={[`evaluationType_${row.evaluationType ?? ""}`, "scaleDetails", index ?? 0, "maxScore"]}
          rules={[
            {
              required: true, message: "Üst Puan yazmalısınız",
              validator: (_, value) =>
                value > 0
                  ? Promise.resolve()
                  : Promise.reject(new Error('Üst puan 0\'dan büyük olmalı')),
            }
          ]}
          initialValue={row.maxScore}
        >
          <Input type="number" style={{ height: 40, margin: 5 }} min={0} max={100} value={row.maxScore ?? 0} className="no-spin" />
        </Form.Item>
      </td>
      <td>
        <Form.Item
          name={[`evaluationType_${row.evaluationType ?? ""}`, "scaleDetails", index ?? 0, "resultDescription"]}
          rules={[
            { required: true, message: "Sonuç Açıklama yazmalısınız" }
          ]}
          initialValue={row.resultDescription}
        >
          <Input.TextArea value={row.resultDescription ?? ''} className="no-resize" style={{ height: 40, margin: 5 }} />
        </Form.Item>
      </td>
      <td>
        <Form.Item
          name={[`evaluationType_${row.evaluationType ?? ""}`, "scaleDetails", index ?? 0, "quotaResult"]}
          rules={[
            {
              required: true, message: "Sonuç Kota yazmalısınız",
              validator: (_, value) =>
                value > 0
                  ? Promise.resolve()
                  : Promise.reject(new Error('Yazılan sayı 0\'dan büyük olmalı')),

            }
          ]}
          initialValue={row.quotaResult}
        >
          <Input type="number" style={{ height: 40, margin: 5 }} min={0} max={100} className="no-spin" />
        </Form.Item>
      </td>
      <td>
        <Form.Item
          name={[`evaluationType_${row.evaluationType ?? ""}`, "scaleDetails", index ?? 0, "isQuotaChecked"]}
          initialValue={row.isQuotaChecked}
        >
          <Select style={{ width: '100%', height: 40, margin: 5 }}>
            <Option value={false}>Hayır</Option>
            <Option value={true}>Evet</Option>
          </Select>
        </Form.Item>
      </td>
      <Form.Item
        style={{ display: "none" }}
        name={[`evaluationType_${row.evaluationType ?? ""}`, "scaleDetails", index ?? 0, "evaluationType"]}
        initialValue={row.evaluationType}
      >
        <input type='hidden' />
      </Form.Item>
    </tr>
  );
};

export default RowComponent;
