// src/pages/PerformanceGoalEntry/PerformanceGoalEntryPage.tsx
import React, { useEffect, useState } from 'react';
import { Button, Form, Table } from 'antd';
import PerformanceGoalEntryModal from '../../components/PerformanceGoalEntry/PerformanceGoalEntryModal';

import { t } from 'i18next';
import { useSelector, useDispatch } from 'react-redux';
import { setLoading, setError } from '../../features/app/appSlice';
import { RootState } from '../../app/store';
import { PerformanceGoalDto, useLazyGetApiPerformanceGoalGetPerformanceGoalsByCompanyGroupIdQuery } from '../../api/services/PerformanceGoal';
import moment from 'moment';

const columns = [
  { title: 'Performans Dönemi', dataIndex: 'period', key: 'period', render: (data: any) => { return `${data.periodName} (${moment(data.startDate).format("DD/MM/YYYY")} - ${moment(data.startDate).format("DD/MM/YYYY")})` } },
  { title: '', dataIndex: 'goalDetails', key: 'goalDetails', render: (data: any) => { return `${data.length} performans kaydı` } },
];

const PerformanceGoalEntryPage: React.FC = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [getPerformanceGoals, { data: performanceGoals }] = useLazyGetApiPerformanceGoalGetPerformanceGoalsByCompanyGroupIdQuery()
  const user = useSelector((state: RootState) => state.auth.user);
  const dispatch = useDispatch();
  const [selectedRow, setselectedRow] = useState<PerformanceGoalDto>()
  const [form] = Form.useForm();

  useEffect(() => {
    if (user) {
      fetchPerformanceGoals()
    }
  }, [user])

  const fetchPerformanceGoals = async () => {
    try {
      dispatch(setLoading(true));
      await getPerformanceGoals({
        companyGroupId: user?.company?.companyGroupId ?? ""
      }).unwrap()
    } catch (err: any) {
      if (err.status != "PARSING_ERROR")
        dispatch(setError(t('Performans hedefleri çekilirken hata oluştu')));
    } finally {
      dispatch(setLoading(false));
    }
  }

  const onModalClose = async () => {
    await fetchPerformanceGoals()
    setModalVisible(false)
    form.resetFields()
  }

  const onRowClick = (values: any) => {
    setselectedRow(values)
    setModalVisible(true)
  }

  const addNewClicked = () => {

    form.resetFields()
    setselectedRow(undefined)
    setModalVisible(true)
  }

  return (
    <>
      <Button type="primary" onClick={() => addNewClicked()} style={{ marginBottom: 16 }}>
        Kurum Hedefi Ekle
      </Button>
      <Table dataSource={performanceGoals}
        columns={columns}
        pagination={false}
        onRow={(record) => ({
          onClick: () => onRowClick(record),
        })} />
      <PerformanceGoalEntryModal
        form={form}
        visible={modalVisible}
        onClose={() => onModalClose()}
        initialValues={selectedRow} />
    </>
  );
};

export default PerformanceGoalEntryPage;
