import { coreApi as api } from "../core/BaseApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiPerformanceSystem: build.query<
      GetApiPerformanceSystemApiResponse,
      GetApiPerformanceSystemApiArg
    >({
      query: (queryArg) => ({
        url: `/api/PerformanceSystem`,
        params: { companyGroupId: queryArg.companyGroupId },
      }),
    }),
    postApiPerformanceSystem: build.mutation<
      PostApiPerformanceSystemApiResponse,
      PostApiPerformanceSystemApiArg
    >({
      query: (queryArg) => ({
        url: `/api/PerformanceSystem`,
        method: "POST",
        body: queryArg.performanceSystemDto,
      }),
    }),
    getApiPerformanceSystemById: build.query<
      GetApiPerformanceSystemByIdApiResponse,
      GetApiPerformanceSystemByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/PerformanceSystem/${queryArg.id}` }),
    }),
    putApiPerformanceSystemById: build.mutation<
      PutApiPerformanceSystemByIdApiResponse,
      PutApiPerformanceSystemByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/PerformanceSystem/${queryArg.id}`,
        method: "PUT",
        body: queryArg.performanceSystemDto,
      }),
    }),
    deleteApiPerformanceSystemById: build.mutation<
      DeleteApiPerformanceSystemByIdApiResponse,
      DeleteApiPerformanceSystemByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/PerformanceSystem/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetApiPerformanceSystemApiResponse =
  /** status 200 OK */ PerformanceSystemDto[];
export type GetApiPerformanceSystemApiArg = {
  companyGroupId?: string;
};
export type PostApiPerformanceSystemApiResponse = unknown;
export type PostApiPerformanceSystemApiArg = {
  performanceSystemDto: PerformanceSystemDto;
};
export type GetApiPerformanceSystemByIdApiResponse =
  /** status 200 OK */ PerformanceSystemDto;
export type GetApiPerformanceSystemByIdApiArg = {
  id: string;
};
export type PutApiPerformanceSystemByIdApiResponse = unknown;
export type PutApiPerformanceSystemByIdApiArg = {
  id: string;
  performanceSystemDto: PerformanceSystemDto;
};
export type DeleteApiPerformanceSystemByIdApiResponse = unknown;
export type DeleteApiPerformanceSystemByIdApiArg = {
  id: string;
};
export type PerformanceSystemType = 1 | 2;
export type OkrDetailDto = {
  id?: string;
  okrShapeId?: string;
  okrRelationshipId?: string;
  okrTypeIds?: string[] | null;
  okrWeightId?: string;
  okrPrivacyId?: string;
  okrTransparencyId?: string;
  okrProgressId?: string;
  minValue?: number;
  maxValue?: number;
};
export type KpiDetailDto = {
  id?: string;
  kpiRelationshipId?: string;
  targetTypesIds?: string[] | null;
  compentencyId?: string;
  goalMeasurementTypeId?: string;
  goalTargetMeasurementSourceIds?: string[] | null;
};
export type KpiGoalCompentencyDetailsDto = {
  id?: string;
  performanceSystemId?: string;
  role?: string;
  goalPercentage?: number;
  competencyPercentage?: number;
  corporateGoals?: number;
  departmentGoals?: number;
  individualGoals?: number;
  managerialCompetencies?: number;
  coreCompetencies?: number;
};
export type RangeType =
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14
  | 15;
export type PerformanceSystemRangeDto = {
  id?: string;
  rangeType?: RangeType;
  minValue?: number;
  maxValue?: number;
};
export type PerformanceSystemDto = {
  id?: string;
  systemNumber?: string | null;
  systemName?: string | null;
  performanceSystemType?: PerformanceSystemType;
  okrDetails?: OkrDetailDto[] | null;
  kpiDetails?: KpiDetailDto[] | null;
  kpiGoalCompentencyDetails?: KpiGoalCompentencyDetailsDto[] | null;
  performanceSystemRanges?: PerformanceSystemRangeDto[] | null;
  companyGroupId?: string;
};
export const {
  useGetApiPerformanceSystemQuery,
  useLazyGetApiPerformanceSystemQuery,
  usePostApiPerformanceSystemMutation,
  useGetApiPerformanceSystemByIdQuery,
  useLazyGetApiPerformanceSystemByIdQuery,
  usePutApiPerformanceSystemByIdMutation,
  useDeleteApiPerformanceSystemByIdMutation,
} = injectedRtkApi;
