import React, { useState, useEffect } from 'react';
import { Modal, Form, Button, Space, Popover, FormInstance } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import ScaleForm from './ScaleForm';
import ScaleTables from './ScaleTables';
import { ScaleTypeNames } from '../../models/ScaleSelection/ScaleType';
import { ScaleDto, ScaleType } from '../../api/services/scale';
import moment from 'moment';

interface ScaleModalProps {
  visible: boolean;
  onCancel: () => void;
  onSave: (values: any) => void;
  initialValues: ScaleDto | null;
  form: FormInstance
}

const ScaleModal: React.FC<ScaleModalProps> = ({ visible, onCancel, onSave, initialValues, form }) => {

  const [selectedScaleType, setSelectedScaleType] = useState<ScaleType | null>(null);

  useEffect(() => {
    if (initialValues?.scaleType) {
      setSelectedScaleType(initialValues.scaleType);
    } else {
      setSelectedScaleType(1);
    }
  }, [initialValues]);

  const content = (
    <div style={{ width: 300 }}>
      <p>Bu sayfada skala tanımlamalarını ve düzenlemelerini yapabilirsiniz. Skala tipi ve değerlerini girdikten sonra, kaydet butonuna basarak verilerinizi kaydedebilirsiniz.</p>
      <p>Her bir skala tipi için, minimum ve maksimum puanları belirleyebilir, açıklamalar ekleyebilirsiniz. Ayrıca kota kontrolü yaparak, belirli oranlarda sonuçların değerlendirilmesini sağlayabilirsiniz.</p>
      <p>Bu bilgiler, performans değerlendirmesi gibi süreçlerde kullanılmak üzere tasarlanmıştır ve özelleştirilebilir yapıdadır.</p>
    </div>
  );

  const getNo = () => {
    return `SS-${moment().format("yyyy-MM-DD-mm")}`;
  }

  return (
    <Modal
      title={
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <span>{initialValues ? "Skala Güncelle" : "Skala Ekle"}</span>
          <Popover content={content} title="Sayfa Bilgisi" trigger="click">
            <Button
              shape="circle"
              icon={<InfoCircleOutlined />}
              style={{
                marginTop: -8,
                marginRight: 30, // Butonu kapatma butonunun soluna al
                zIndex: 1000,
              }}
            />
          </Popover>
        </div>
      }
      visible={visible}
      onCancel={onCancel}
      footer={
        <div style={{ textAlign: 'right' }}>
          <Space>
            <Button type="primary" htmlType="submit" onClick={() => form.submit()}>
              Kaydet
            </Button>
            <Button onClick={onCancel}>
              İptal
            </Button>
          </Space>
        </div>
      }
      width={1000}
      bodyStyle={{ maxHeight: '65vh', overflowY: 'auto', paddingRight: '16px' }}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onSave}
        initialValues={initialValues || { scaleNumber: getNo() }}  // Boş form için {}
      >
        <ScaleForm scaleData={initialValues || null} form={form} selectedScaleType={selectedScaleType} setSelectedScaleType={setSelectedScaleType} />
        <ScaleTables scaleType={selectedScaleType} scaleData={initialValues || null} />
      </Form>
    </Modal>
  );
};

export default ScaleModal;
