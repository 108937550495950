import React, { ReactNode, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, store } from '../app/store';
import { clearError } from '../features/app/appSlice';
import { Spin, Alert } from 'antd';
import { Layout } from 'antd';
import axios from 'axios';
import { usePostApiAuthRefreshTokenMutation } from '../api/services/auth';
import { logout, toggleLoggedIn } from '../app/persistedSlice';
import { Redirect } from 'react-router-dom';

const { Content } = Layout;

interface BaseViewProps {
  children: ReactNode;
}

const BaseView: React.FC<BaseViewProps> = ({ children }) => {
  const { loading, error } = useSelector((state: RootState) => state.app);
  // const { refreshToken: refreshTokenValue } = useSelector((state: RootState) => state.auth);
  const [renewRefreshToken] = usePostApiAuthRefreshTokenMutation()
  const refreshToken = useSelector((state: RootState) => state.persisted.refreshToken)
  const accessToken = useSelector((state: RootState) => state.persisted.accessToken)
  const dispatch = useDispatch();

  useEffect(() => {
    const interval = setInterval(async () => {
      try {
        await renewRefreshToken({
          tokenModel: {
            refreshToken: refreshToken,
            token: accessToken
          }
        }).unwrap()
      } catch (err) {
        clearInterval(interval);
        dispatch(logout());
        dispatch(toggleLoggedIn(false));
      }
    }, 200000); // 15 dakikada bir token yenileme 15 * 60 * 
    return () => clearInterval(interval);
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        dispatch(clearError());
      }, 3000);
    }
  }, [error])

  return (
    <Layout>
      {loading && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex justify-center items-center z-50">
          <Spin size="large" />
        </div>
      )}
      {error && (
        <Alert
          message="Error"
          description={error}
          type="error"
          showIcon
          closable
          onClose={() => dispatch(clearError())}
          className="fixed top-4 right-4 z-50"
        />
      )}
      <Content>
        {children}
      </Content>
    </Layout>
  );
};

export default BaseView;
