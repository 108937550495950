import { coreApi as api } from "../core/BaseApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiScale: build.query<GetApiScaleApiResponse, GetApiScaleApiArg>({
      query: () => ({ url: `/api/Scale` }),
    }),
    postApiScale: build.mutation<PostApiScaleApiResponse, PostApiScaleApiArg>({
      query: (queryArg) => ({
        url: `/api/Scale`,
        method: "POST",
        body: queryArg.scaleDto,
      }),
    }),
    getApiScaleById: build.query<
      GetApiScaleByIdApiResponse,
      GetApiScaleByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Scale/${queryArg.id}` }),
    }),
    putApiScaleById: build.mutation<
      PutApiScaleByIdApiResponse,
      PutApiScaleByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Scale/${queryArg.id}`,
        method: "PUT",
        body: queryArg.scaleDto,
      }),
    }),
    deleteApiScaleById: build.mutation<
      DeleteApiScaleByIdApiResponse,
      DeleteApiScaleByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Scale/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetApiScaleApiResponse = /** status 200 OK */ ScaleDto[];
export type GetApiScaleApiArg = void;
export type PostApiScaleApiResponse = unknown;
export type PostApiScaleApiArg = {
  scaleDto: ScaleDto;
};
export type GetApiScaleByIdApiResponse = /** status 200 OK */ ScaleDto;
export type GetApiScaleByIdApiArg = {
  id: string;
};
export type PutApiScaleByIdApiResponse = unknown;
export type PutApiScaleByIdApiArg = {
  id: string;
  scaleDto: ScaleDto;
};
export type DeleteApiScaleByIdApiResponse = unknown;
export type DeleteApiScaleByIdApiArg = {
  id: string;
};
export type ScaleType = 1 | 2 | 3;
export type EvaluationType = 1 | 2;
export type ScaleDetailDto = {
  result?: string | null;
  minScore?: number;
  maxScore?: number;
  resultDescription?: string | null;
  quotaResult?: number;
  isQuotaChecked?: boolean;
  evaluationType?: EvaluationType;
  key?: any;
};
export type ScaleDto = {
  id?: string;
  scaleNumber?: string | null;
  scaleName?: string | null;
  scaleType?: ScaleType;
  scaleDetails?: ScaleDetailDto[] | null;
  companyGroupId?: string;
  key?: any;
};
export const {
  useGetApiScaleQuery,
  useLazyGetApiScaleQuery,
  usePostApiScaleMutation,
  useGetApiScaleByIdQuery,
  useLazyGetApiScaleByIdQuery,
  usePutApiScaleByIdMutation,
  useDeleteApiScaleByIdMutation,
} = injectedRtkApi;
