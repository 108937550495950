import React, { useEffect, useState } from 'react';
import { Table, Typography, Divider, Form } from 'antd';
import './ScaleTables.css';
import { EvaluationType, ScaleDetailDto, ScaleDto, ScaleType } from '../../api/services/scale';
import RowComponent from './RowComponent';

const { Title } = Typography;

interface ScaleTablesProps {
  scaleType: ScaleType | null;
  scaleData: ScaleDto | null;
}

const ScaleTables: React.FC<ScaleTablesProps> = ({ scaleType, scaleData }) => {
  // if (!scaleType) return null;

  const [rowCount, setRowCount] = useState(scaleType === 1 ? 3 : scaleType === 2 ? 4 : 5)
  const [data, setData] = useState<ScaleDetailDto[]>([])

  useEffect(() => {
    if (scaleType) {
      setRowCount(scaleType === 1 ? 3 : scaleType === 2 ? 4 : 5)
    }
  }, [scaleType])

  useEffect(() => {
    if (scaleData && scaleData.scaleDetails && scaleData.scaleDetails.length > 0) {
      setData(scaleData.scaleDetails)
    } else {
      var first = Array.from({ length: rowCount }, (_, i) => ({
        key: i + 1,
        result: '',
        minScore: 0,
        maxScore: 0,
        resultDescription: '',
        quotaResult: 0,
        isQuotaChecked: false,
        evaluationType: 1
      } as ScaleDetailDto))

      var second = Array.from({ length: rowCount }, (_, i) => ({
        key: i + 1,
        result: '',
        minScore: 0,
        maxScore: 0,
        resultDescription: '',
        quotaResult: 0,
        isQuotaChecked: false,
        evaluationType: 2
      } as ScaleDetailDto))

      setData([...first, ...second])
    }
  }, [scaleData, rowCount])

  const handleRowChange = (key: number, updatedRow: ScaleDetailDto) => {
    const updatedData = data.map(item => (item.key === key ? updatedRow : item));
    setData(updatedData);
  };

  const renderTable = (title: string, type: EvaluationType) => (
    <>
      <Divider orientation="left">
        <Title level={5} style={{ marginBottom: 0 }}>{title}</Title>
      </Divider>
      <table>
        <thead>
          <tr>
            <th style={{ width: 200 }}>Sonuç</th>
            <th style={{ width: 200 }}>Alt Puan</th>
            <th style={{ width: 200 }}>Üst Puan</th>
            <th style={{ width: 300 }}>Sonuç Açıklama</th>
            <th style={{ width: 200 }}>Sonuç Kota</th>
            <th style={{ width: 200 }}>Kota Uygunluğu</th>
          </tr>
        </thead>
        <tbody>
          {data
            ?.filter(k => k.evaluationType === type)
            .map((row, index) => (
              <RowComponent key={row.key} index={index} row={row} onRowChange={handleRowChange} />
            ))}
        </tbody>
      </table>
    </>
  );

  return (
    <>
      {renderTable('Ne Yaptık? (Hedefler)', 1)}
      {renderTable('Nasıl Yaptık? (Yetkinlikler)', 2)}
    </>
  );
};

export default ScaleTables;
