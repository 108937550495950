import { BaseQueryFn, FetchArgs, FetchBaseQueryError, createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { setAccessToken, setRefreshToken, toggleLoggedIn } from '../../app/persistedSlice'
import type { RootState } from '../../app/store'
import { TokenResponseModel } from '../services/auth'

export const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_BASE_URL,
    timeout: 300000,
    prepareHeaders: (headers, { getState, endpoint }) => {
        const accessToken = (getState() as RootState).persisted.accessToken
        const refreshToken = (getState() as RootState).persisted.refreshToken

        if (accessToken && accessToken != "") {
            headers.set('authorization', `Bearer ${accessToken}`)
        }

        if (refreshToken && refreshToken != "" && endpoint.includes('refresh-token'))
            headers.set('authorization', `Bearer ${refreshToken}`)
        return headers
    },
})

export type ApiErrorWithCodeData = {
    message: string
    errorCode: number
    errorGroup: string
};
export type ApiErrorWithCode = {
    status: string
    data: ApiErrorWithCodeData
};


export function instanceOfApiErrorWithCode(test: any): test is ApiErrorWithCode {
    return 'data' in test && 'errorCode' in test.data;
}

export const baseQueryWithReauth: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError | ApiErrorWithCode
> = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions)
    if (result.error && (result.error.status === 401 || result.error.status == 'FETCH_ERROR')) {
        // try to get a new token
        const refreshResult = await baseQuery({
            url: 'api/auth/refresh-token',
            method: 'POST',
            body: {
                "refreshToken": (api.getState() as RootState).persisted.refreshToken,
                "token": (api.getState() as RootState).persisted.accessToken
            }
        }, api, extraOptions)

        if (refreshResult.data) {
            // store the new token
            api.dispatch(setAccessToken((refreshResult.data as TokenResponseModel).token ?? ""))
            api.dispatch(setRefreshToken((refreshResult.data as TokenResponseModel).refreshToken ?? ""))
            
            // retry the initial query
            result = await baseQuery(args, api, extraOptions)
        } else {
            api.dispatch(toggleLoggedIn(false))
        }
    }
    return result
}

export const coreApi = createApi({
    baseQuery: baseQueryWithReauth,
    endpoints: () => ({}),

})