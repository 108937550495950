import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import PerformanceSystemTable from '../../components/PerformanceSystem/PerformanceSystemTable';
import PerformanceSystemModal from '../../components/PerformanceSystem/PerformanceSystemModal';
import { PerformanceSystemType } from '../../enums/PerformanceSystemType';
import { dummyData, PerformanceSystem } from '../../components/PerformanceSystem/dummyData';
import { PerformanceSystemDto, PerformanceSystemRangeDto, useLazyGetApiPerformanceSystemQuery, usePostApiPerformanceSystemMutation, usePutApiPerformanceSystemByIdMutation } from '../../api/services/performanceSystem';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { setError, setLoading } from '../../features/app/appSlice';
import { t } from 'i18next';
import { ParameterType, usePostApiParameterGetParametersMutation } from '../../api/services/parameters';
import { Guid } from 'typescript-guid';
import { DataItem } from '../../models/DataItem';
import { extractParam } from '../../helper/paramHelper';


const PerformanceSystemPage: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedSystem, setSelectedSystem] = useState<PerformanceSystemDto | null>(null);
  const [fetchPerformanceSystems, { data: systems }] = useLazyGetApiPerformanceSystemQuery()
  const [insertPerformanceSystem] = usePostApiPerformanceSystemMutation();
  const [UpdatePerformanceSystem] = usePutApiPerformanceSystemByIdMutation();
  const [getParameters, { data: parameters }] = usePostApiParameterGetParametersMutation()
  const [userRoles, setUserRoles] = useState<DataItem[]>([])

  const user = useSelector((state: RootState) => state.auth.user);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user)
      fetchSystems()
  }, [user])


  useEffect(() => {
    if (user) {
      getParameters({
        companyGroupId: user.company?.companyGroupId ?? "",
        body: [
          2 as ParameterType, //KULLANICI_ROLLER

          5 as ParameterType, //KpiGoalTypes
          6 as ParameterType, //KpiGoalCalculationTypes
          7 as ParameterType, //KpiGoalCalculationSource
          8 as ParameterType, //KpiCompentencySelections

          10 as ParameterType, //okrShapes
          11 as ParameterType, //okrRelations
          12 as ParameterType, //okrTypes
          13 as ParameterType, //okrWeightings
          14 as ParameterType, //okrPrivacies
          15 as ParameterType, //okrTransparencies
          16 as ParameterType, //okrProgressions
        ]
      })
    }
  }, [user])

  useEffect(() => {
    if (parameters) {
      setUserRoles(extractParam(parameters, 2))
    }
  }, [parameters])

  const fetchSystems = async () => {
    try {
      dispatch(setLoading(true));
      await fetchPerformanceSystems({
        companyGroupId: user?.company?.companyGroupId ?? ""
      }).unwrap()
    } catch (err: any) {
      if (err.status != "PARSING_ERROR")
        dispatch(setError(t('Sistemler sorgulanırken hata oluştu')));
    } finally {
      dispatch(setLoading(false));
    }
  }

  const InsertSystem = async (values: any) => {
    try {
      dispatch(setLoading(true));
      var payload = {
        companyGroupId: user?.company?.companyGroupId ?? "",
        systemName: values.systemName ?? "",
        systemNumber: values.systemNumber ?? "",
        performanceSystemType: values.performanceSystemType ?? 1,
        okrDetails: [],
        kpiDetails: [],
        kpiGoalCompentencyDetails: [],
        performanceSystemRanges: [
          //@ts-ignore
          ...(values.action.map((k, index) => { return ((k == undefined) ? null : { rangeType: index, minValue: k.minValue, maxValue: k.maxValue } as PerformanceSystemDto) }).filter((k: any) => k != null)),
          //@ts-ignore
          ...((values.weight ?? []).map((k, index) => { return ((k == undefined) ? null : { rangeType: index, minValue: k.minValue, maxValue: k.maxValue } as PerformanceSystemDto) }).filter((k: any) => k != null))
        ]
      } as PerformanceSystemDto

      if (values.performanceSystemType == 1) {
        payload.okrDetails = [{
          okrShapeId: values.okrShape ?? "",
          okrRelationshipId: values.okrRelation ?? "",
          okrTypeIds: (values.okrTypes ?? []).map((k: any) => k),
          okrWeightId: values.okrWeighting ?? "",
          okrPrivacyId: values.okrPrivacy ?? "",
          okrTransparencyId: values.okrTransparency ?? "",
          okrProgressId: values.okrProgression ?? "",
        }]
      }

      if (values.performanceSystemType == 2) {
        payload.kpiDetails = [{
          compentencyId: values.kpiCompentencySelection ?? "",
          goalMeasurementTypeId: values.kpiGoalCalculationType ?? "",
          goalTargetMeasurementSourceIds: (values.kpiGoalCalculationSource ?? []).map((k: any) => k),
          kpiRelationshipId: values.kpiRelation ?? "",
          targetTypesIds: (values.kpiGoalTypes ?? []).map((k: any) => k),
        }]

        payload.kpiGoalCompentencyDetails = userRoles.map(k => {
          return {
            role: k.id ?? "",
            goalPercentage: values.goal_compentency.goalPercentage[k.id ?? ""],
            competencyPercentage: values.goal_compentency.competencyPercentage[k.id ?? ""],
            corporateGoals: values.goals_sub.corporateGoals[k.id ?? ""],
            departmentGoals: values.goals_sub.departmentGoals[k.id ?? ""],
            individualGoals: values.goals_sub.individualGoals[k.id ?? ""],
            managerialCompetencies: values.compentency_sub.managerialCompetencies[k.id ?? ""],
            coreCompetencies: values.compentency_sub.coreCompetencies[k.id ?? ""]
          }
        })
      }

      await insertPerformanceSystem({
        performanceSystemDto: payload
      }).unwrap()

    } catch (err: any) {
      if (err.status != "PARSING_ERROR")
        dispatch(setError(t('Sistem eklenirken hata oluştu')));
    } finally {
      dispatch(setLoading(false));
    }

    fetchSystems()
  }

  const UpdateSystem = async (values: any) => {
    try {
      dispatch(setLoading(true));

      var payload = {
        companyGroupId: user?.company?.companyGroupId ?? "",
        systemName: values.systemName ?? "",
        systemNumber: values.systemNumber ?? "",
        performanceSystemType: values.performanceSystemType ?? 1,
        okrDetails: [],
        kpiDetails: [],
        kpiGoalCompentencyDetails: [],
        performanceSystemRanges: [
          // //@ts-ignore
          // ...(values.action.map((k, index) => { return ((k == undefined) ? null : { rangeType: index, minValue: k.minValue, maxValue: k.maxValue } as PerformanceSystemDto) }).filter((k: any) => k != null)),
          // //@ts-ignore
          // ...((values.weight ?? []).map((k, index) => { return ((k == undefined) ? null : { rangeType: index, minValue: k.minValue, maxValue: k.maxValue } as PerformanceSystemDto) }).filter((k: any) => k != null))
        ]
      } as PerformanceSystemDto

      if (values.performanceSystemType == 1) {
        //update okr actions
        values.action.forEach((k: any, i: number) => {
          if (k != undefined) {
            var actionId = selectedSystem?.performanceSystemRanges?.find(k => k.rangeType == i)?.id ?? Guid.create().toString()
            var range = { id: actionId, rangeType: i, minValue: k.minValue, maxValue: k.maxValue } as PerformanceSystemDto
            payload.performanceSystemRanges?.push(range)
          }
        });

        //update okr weight
        values.weight.forEach((k: any, i: number) => {
          if (k != undefined) {
            var actionId = selectedSystem?.performanceSystemRanges?.find(k => k.rangeType == i)?.id ?? Guid.create().toString()
            var range = { id: actionId, rangeType: i, minValue: k.minValue, maxValue: k.maxValue } as PerformanceSystemDto
            payload.performanceSystemRanges?.push(range)
          }
        });
      }else {
        // //update kpi actions
        // values.action.forEach((k: any, i: number) => {
        //   if (k != undefined) {
        //     var actionId = selectedSystem?.performanceSystemRanges?.find(k => k.rangeType == i)?.id ?? ""
        //     var range = { id: actionId, rangeType: i, minValue: k.minValue, maxValue: k.maxValue } as PerformanceSystemDto
        //     payload.performanceSystemRanges?.push(range)
        //   }
        // });

        // //update kpi weight
        // values.weight.forEach((k: any, i: number) => {
        //   if (k != undefined) {
        //     var actionId = selectedSystem?.performanceSystemRanges?.find(k => k.rangeType == i)?.id ?? ""
        //     var range = { id: actionId, rangeType: i, minValue: k.minValue, maxValue: k.maxValue } as PerformanceSystemDto
        //     payload.performanceSystemRanges?.push(range)
        //   }
        // });
      }

      
      if (values.performanceSystemType == 1) {
        payload.okrDetails = [{
          id: selectedSystem?.okrDetails ? selectedSystem?.okrDetails[0].id : "",
          okrShapeId: values.okrShape ?? "",
          okrRelationshipId: values.okrRelation ?? "",
          okrTypeIds: (values.okrTypes ?? []).map((k: any) => k),
          okrWeightId: values.okrWeighting ?? "",
          okrPrivacyId: values.okrPrivacy ?? "",
          okrTransparencyId: values.okrTransparency ?? "",
          okrProgressId: values.okrProgression ?? "",
        }]
      }

      if (values.performanceSystemType == 2) {
        payload.kpiDetails = [{
          compentencyId: values.kpiCompentencySelection ?? "",
          goalMeasurementTypeId: values.kpiGoalCalculationType ?? "",
          goalTargetMeasurementSourceIds: (values.kpiGoalCalculationSource ?? []).map((k: any) => k),
          kpiRelationshipId: values.kpiRelation ?? "",
          targetTypesIds: (values.kpiGoalTypes ?? []).map((k: any) => k),
        }]

        payload.kpiGoalCompentencyDetails = userRoles.map(k => {
          return {
            role: k.id ?? "",
            goalPercentage: values.goal_compentency.goalPercentage[k.id ?? ""],
            competencyPercentage: values.goal_compentency.competencyPercentage[k.id ?? ""],
            corporateGoals: values.goals_sub.corporateGoals[k.id ?? ""],
            departmentGoals: values.goals_sub.departmentGoals[k.id ?? ""],
            individualGoals: values.goals_sub.individualGoals[k.id ?? ""],
            managerialCompetencies: values.compentency_sub.managerialCompetencies[k.id ?? ""],
            coreCompetencies: values.compentency_sub.coreCompetencies[k.id ?? ""]
          }
        })
      }

      await UpdatePerformanceSystem({
        id: selectedSystem?.id ?? "",
        performanceSystemDto: payload
      }).unwrap()

    } catch (err: any) {
      if (err.status != "PARSING_ERROR")
        dispatch(setError(t('Sistem eklenirken hata oluştu')));
    } finally {
      dispatch(setLoading(false));
    }

    fetchSystems()
  }

  const handleRowClick = (record: PerformanceSystemDto) => {
    setSelectedSystem(record);
    setIsModalVisible(true);
  };

  const handleAddSystem = () => {
    setSelectedSystem(null);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedSystem(null);
  };

  const handleSave = async (values: any) => {
    console.log('Saved values:', values);

    if (selectedSystem == null)
      await InsertSystem(values);
    else
      await UpdateSystem(values);

    setIsModalVisible(false);
  };

  return (
    <div>
      <Button type="primary" onClick={handleAddSystem} style={{ marginBottom: 16 }}>
        Performans Sistemi Ekle
      </Button>
      <PerformanceSystemTable data={systems} onRowClick={handleRowClick} />
      {isModalVisible && (
        <PerformanceSystemModal
          visible={isModalVisible}
          onCancel={handleCancel}
          onSave={handleSave}
          initialValues={selectedSystem}
          parameters={parameters}
        />
      )}
    </div>
  );
};

export default PerformanceSystemPage;
