import React, { useState } from 'react';
import { Table, Button, Input, Select, Form } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import GoalTableRow from './GoalTableRow';
import GoalTableSummary from './GoalTableSummary';
import TextArea from 'antd/es/input/TextArea';
import { kpiGoalCalculationSource } from '../../data/data';
import { DataItem } from '../../models/DataItem';
import { PerformanceGoalDetailDto, PerformanceGoalDto } from '../../api/services/PerformanceGoal';

const initialRows: PerformanceGoalDetailDto[] = [
  { strategicGoal: '', kpi: '', weight: 0, source: '', minResult: 0, targetResult: 0, maxResult: 0 },
  { strategicGoal: '', kpi: '', weight: 0, source: '', minResult: 0, targetResult: 0, maxResult: 0 },
  { strategicGoal: '', kpi: '', weight: 0, source: '', minResult: 0, targetResult: 0, maxResult: 0 },
];



export interface GoalTableParams {
  goal: PerformanceGoalDto | undefined
}

const GoalTable: React.FC<GoalTableParams> = ({ goal }) => {
  const [dataSource, setDataSource] = useState<PerformanceGoalDetailDto[]>(goal?.goalDetails ?? initialRows);

  const handleAddRow = () => {
    const newRow: PerformanceGoalDetailDto = {
      id: `${dataSource.length + 1}`,
      strategicGoal: '',
      kpi: '',
      weight: 0,
      source: '',
      minResult: 0,
      targetResult: 0,
      maxResult: 0,
    };
    setDataSource([...dataSource, newRow]);
  };

  const handleChange = (key: string, field: string, value: any) => {
    const newData = dataSource.map((row) => {
      if (row.id === key) {
        return { ...row, [field]: value };
      }
      return row;
    });
    setDataSource(newData);
  };

  const calculateTotal = (field: keyof PerformanceGoalDetailDto) => {
    return 0 //dataSource.reduce((sum, row) => sum + (Number(row[field]) || 0), 0);
  };

  const totalRow = {
    weight: calculateTotal('weight'),
    minResult: calculateTotal('minResult'),
    targetResult: calculateTotal('targetResult'),
    maxResult: calculateTotal('maxResult'),
  };

  const columns = [
    {
      title: 'Stratejik Hedef',
      dataIndex: 'strategicGoal',
      key: 'strategicGoal',
      render: (text: string, record: PerformanceGoalDetailDto, index: number) => (
        <Form.Item
          name={["rows", index, "strategicGoal"]}
          // rules={[
          //   { required: true, message: "Sonuç Açıklama yazmalısınız" }
          // ]}
          initialValue={text}
        >
          <TextArea
            value={text}
            onChange={(e) => handleChange(record.id ?? "", 'strategicGoal', e.target.value)}
            style={{ height: '40px', width: '200px', resize: 'none' }} // TextArea için yükseklik ayarı
          />
        </Form.Item>

      ),
    },
    {
      title: 'KPI',
      dataIndex: 'kpi',
      key: 'kpi',
      render: (text: string, record: PerformanceGoalDetailDto, index: number) => (
        <Form.Item
          name={["rows", index, "kpi"]}
          // rules={[
          //   { required: true, message: "Sonuç Açıklama yazmalısınız" }
          // ]}
          initialValue={text}
        >
          <TextArea
            value={text}
            onChange={(e) => handleChange(record.id ?? "", 'kpi', e.target.value)}
            style={{ height: '40px', width: '200px', resize: 'none' }} // TextArea için yükseklik ayarı
          />
        </Form.Item>

      ),
    },
    {
      title: 'Hedef Ağırlık %',
      dataIndex: 'weight',
      key: 'weight',
      render: (text: number, record: PerformanceGoalDetailDto, index: number) => (
        <Form.Item
          name={["rows", index, "weight"]}
          // rules={[
          //   { required: true, message: "Sonuç Açıklama yazmalısınız" }
          // ]}
          initialValue={text}
        >
          <Input
            type="number"
            min={0}
            max={100}
            value={text}
            onChange={(e) => handleChange(record.id ?? "", 'weight', parseFloat(e.target.value))}
            suffix="%"
            style={{ height: '40px' }} // Input için yükseklik ayarı
          />
        </Form.Item>

      ),
    },
    {
      title: 'Hedef Ölçüm Kaynağı',
      dataIndex: 'source',
      key: 'source',
      render: (text: string, record: PerformanceGoalDetailDto, index: number) => (
        <Form.Item
          name={["rows", index, "source"]}
          // rules={[
          //   { required: true, message: "Sonuç Açıklama yazmalısınız" }
          // ]}
          initialValue={text}
        >

          <Select
            value={text}
            onChange={(value) => handleChange(record.id ?? "", 'source', value)}
            style={{ height: '40px', width: '250px' }} // Select için yükseklik ayarı
          >
            {kpiGoalCalculationSource.map((item: DataItem) => (
              <Select.Option key={item.id} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

      ),
    },
    {
      title: 'Min Hedef Sonuç',
      dataIndex: 'minResult',
      key: 'minResult',
      render: (text: number, record: PerformanceGoalDetailDto, index: number) => (
        <Form.Item
          name={["rows", index, "minResult"]}
          // rules={[
          //   { required: true, message: "Sonuç Açıklama yazmalısınız" }
          // ]}
          initialValue={text}
        >

          <Input
            type="number"
            min={0}
            max={100}
            value={text}
            onChange={(e) => handleChange(record.id ?? "", 'minResult', parseFloat(e.target.value))}
            suffix="%"
            style={{ height: '40px' }} // Input için yükseklik ayarı
          />
        </Form.Item>

      ),
    },
    {
      title: 'Hedef Sonuç',
      dataIndex: 'targetResult',
      key: 'targetResult',
      render: (text: number, record: PerformanceGoalDetailDto, index: number) => (
        <Form.Item
          name={["rows", index, "targetResult"]}
          // rules={[
          //   { required: true, message: "Sonuç Açıklama yazmalısınız" }
          // ]}
          initialValue={text}
        >
          <Input
            type="number"
            min={0}
            max={100}
            value={text}
            onChange={(e) => handleChange(record.id ?? "", 'targetResult', parseFloat(e.target.value))}
            suffix="%"
            style={{ height: '40px' }} // Input için yükseklik ayarı
          />
        </Form.Item>
      ),
    },
    {
      title: 'Max Hedef Sonuç',
      dataIndex: 'maxResult',
      key: 'maxResult',
      render: (text: number, record: PerformanceGoalDetailDto, index: number) => (
        <Form.Item
          name={["rows", index, "maxResult"]}
          // rules={[
          //   { required: true, message: "Sonuç Açıklama yazmalısınız" }
          // ]}
          initialValue={text}
        >
          <Input
            type="number"
            min={0}
            max={100}
            value={text}
            onChange={(e) => handleChange(record.id ?? "", 'maxResult', parseFloat(e.target.value))}
            suffix="%"
            style={{ height: '40px' }} // Input için yükseklik ayarı
          />
        </Form.Item>

      ),
    },
  ];


  return (
    <>
      <Button onClick={handleAddRow} type="primary" icon={<PlusOutlined />} style={{ marginBottom: 16 }}>
        Satır Ekle
      </Button>
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        bordered
        rowKey="key"
        summary={() => <GoalTableSummary total={totalRow} />}
      />
    </>
  );
};

export default GoalTable;
