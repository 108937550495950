// src/components/PerformanceGoalEntry/GoalTableSummary.tsx
import React from 'react';
import { Input, Table } from 'antd';

interface GoalTableSummaryProps {
  total: {
    weight: number;
    minResult: number;
    targetResult: number;
    maxResult: number;
  };
}

const GoalTableSummary: React.FC<GoalTableSummaryProps> = ({ total }) => {
  return (
    <Table.Summary.Row>
      <Table.Summary.Cell index={0}>Toplam</Table.Summary.Cell>
      <Table.Summary.Cell index={1} />
      <Table.Summary.Cell index={2}>
        <Input value={total.weight} suffix="%" disabled />
      </Table.Summary.Cell>
      <Table.Summary.Cell index={3} />
      <Table.Summary.Cell index={4}>
        <Input value={total.minResult} suffix="%" disabled />
      </Table.Summary.Cell>
      <Table.Summary.Cell index={5}>
        <Input value={total.targetResult} suffix="%" disabled />
      </Table.Summary.Cell>
      <Table.Summary.Cell index={6}>
        <Input value={total.maxResult} suffix="%" disabled />
      </Table.Summary.Cell>
    </Table.Summary.Row>
  );
};

export default GoalTableSummary;
